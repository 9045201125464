@import '../variables';
.modal {
    &-video {
        > .modal-dialog {
            width: 90%;
            max-width: none;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
            > .modal-content {
                border: none;
                background-color: transparent;
                > .modal {
                    &-header {
                        padding: 0;
                        border: none;
                        > .modal-title {
                            display: none;
                        }
                        > .close {
                            color: $c-wh;
                            font-size: 60px;
                            opacity: 1;
                        }
                    }
                    &-body {
                        padding: 0;
                        > iframe {
                            width: 100%;
                            height: 210px;
                        }
                        
                        > img {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media (min-width: 850px) {
            > .modal-dialog {
                height: 85%;

                > .modal-content {
                    height: 100%;
                    > .modal {
                        &-body {
                            height: 100%;
                            > iframe {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    &-gallery {
        > .modal-dialog {
            width: 100%;
            height: 100% !important;
            max-width: none;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
            > .modal-content {
                border: none;
                background-color: transparent;
                > .modal {
                    &-header {
                        padding: 0;
                        border: none;
                        > .modal-title {
                            display: none;
                        }
                        > .close {
                            color: $c-wh;
                            font-size: 60px;
                            opacity: 1;
                        }
                    }
                    &-body {
                        padding: 0;
                        .galleries{
                            &.type_1{
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 850px) {
            > .modal-dialog {
                height: 85%;

                > .modal-content {
                    height: 100%;
                    > .modal {
                        &-body {
                            height: 100%;
                            > iframe {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
