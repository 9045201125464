@font-face {
    font-family: "NexaBold";
    font-display: swap;
    src: url("../fonts/nexa/NexaBold.ttf") format("truetype");
}
@font-face {
    font-family: "NexaLight";
    font-display: swap;
    src: url("../fonts/nexa/NexaLight.ttf") format("truetype");
}
$c-1: #98d789;
$c-dr: #222a2c;
$c-wh: #fff;
$c-gr: #eee;
$c-gr-2: #9e9e9e;
$c-gr-3: #ebebeb;
// margins
$mrg: 25px;
// fonts
$f-ppal: "NexaLight";
$f-ppalBold: "NexaBold";
