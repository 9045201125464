@import "../variables";

$sz-control: 40px;

.form-group {
    position: relative;
    margin-bottom: 25px;

    > label {
        position: absolute;
        display: flex;
        touch-action: manipulation;
        text-transform: uppercase;
        padding: 0 6px;
        left: 6px;
        top: -5px;
        color: $c-dr;
        font-size: 10px;
        letter-spacing: 2px;
        background-color: $c-wh;
        pointer-events: none;
        transition: all 0.2s;
    }
    > input {
        width: 100%;
        border-radius: 0;
        touch-action: manipulation;
        padding: 0 12px;
        height: $sz-control;
        background-color: transparent;
        border: 1px solid #d4d4d4;
        font-size: 15px;
        font-weight: bold;
        transition: all 0.2s;

        &:hover {
            border-color: #a8a8a8;
        }

        &:placeholder-shown {
            + label {
                top: 15px;
                font-size: 12px;
            }
        }

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(0, 0, 0, 0.5);
            opacity: 0; /* Firefox */
            transition: all 0.3s;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(0, 0, 0, 0.5);
            opacity: 0; /* Firefox */
            transition: all 0.3s;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(0, 0, 0, 0.5);
            opacity: 0; /* Firefox */
            transition: all 0.3s;
        }

        &:focus {
            border-color: $c-1;
            + label {
                top: -5px;
                font-size: 10px;
                color: $c-dr;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(0, 0, 0, 0.5);
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(0, 0, 0, 0.5);
                opacity: 1;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba(0, 0, 0, 0.5);
                opacity: 1;
            }
        }

        &.error{
            border-color: #ff0000;
        }
    }

    > textarea {
        width: 100%;
        border-radius: 0;
        resize: vertical;
        min-height: 70px;
        font-size: 15px;
        padding: 12px;
        font-weight: bold;
        border: 1px solid #d4d4d4;
        transition: border-color .2s;
        &:hover {
            border-color: #a8a8a8;
        }
        &:focus {
            border-color: $c-1;
        }

        &.error{
            border-color: #ff0000;
        }
    }

    > .alert-form{
        margin-top: 5px;
        font-size: 13px;
        color: #ff0000;
        font-weight: bold;
    }

    &.required{
        label{
            &::after{
                content: '*';
                color: rgb(87, 126, 209);
                margin-top: -5px;
                margin-left: 5px;
                font-size: 20px;
                font-weight: bold;
                display: block;
            }
        }
    }
}

.form{
    position: relative;
    > .form-footer{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        > .send{
            &::before{
                content: 'Enviar';
                display: block;
            }
        }
    }

    &.emailjs-sending{
        > .form-footer{
            > .send{
                opacity: .6;
                pointer-events: none;
                &::before{
                    content: 'Enviando...';
                }
            }
        }
    }

    > .alert{
        border: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60%;
        margin: 0;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        border-radius: 0;
        padding: 15px;

        > strong{
            font-size: 15px;
            font-family: $f-ppalBold;
            margin-bottom: 10px;
        }
    }
}