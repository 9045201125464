@import '../variables';

.btn{
    padding: 0 20px;
    height: 40px;
    border: none;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    flex: 1 0 auto;
    line-height: 1;
    font-size: 13px;
    text-transform: uppercase;
    &.c-transparent{
        background-color: transparent;
        color: $c-wh;
    }
    &.c-dr{
        background-color: $c-dr;
        color: $c-wh;
    }

    &.outline{
        border: 1.6px solid transparent;
        &.c-wh{
            border-color: $c-wh;
            color: $c-wh;
            font-weight: 600;
            font-size: 18px;
        }
    }

    &[disabled]{
        cursor: not-allowed;
    }
}