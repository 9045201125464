@import "./assets/sass/reset";
@import "./assets/sass/variables";
@import "./assets/sass/modules/buttons";
@import "./assets/sass/modules/modals";
@import "./assets/sass/modules/forms";

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
    font-family: $f-ppal;
}
a {
    &:hover {
        text-decoration: none;
    }
}
// delete outline
* {
    outline: none !important;
    box-sizing: border-box;
    &:focus {
        box-shadow: none !important;
        outline: none !important;
    }
}

// titles
.title-ppal {
    line-height: 1.1;
    font-family: $f-ppalBold;
    @media (min-width: 801px) {
        font-size: 45px;
    }
    @media (max-width: 800px) {
        font-size: 30px;
    }
}
.parag-ppal {
    line-height: normal;
    @media (min-width: 801px) {
        font-size: 19px;
    }
    @media (max-width: 800px) {
        font-size: 18px;
        font-weight: 300;
    }
}

// list defualt
.list-default {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
